// 印尼
export default {
  route: {
    //   banner1
    Slogan1: 'Memberi pinjaman digital dengan AI dan algoritme',
    introduction: 'Perusahaan teknologi pinjaman digital terkemuka, melalui kecerdasan buatan dan teknologi algoritmik, memberi pengguna korporat intelijen digital, pemasaran, manajemen risiko, dan layanan konsultasi bisnis, untuk membantu perusahaan meningkatkan kemampuan dan efisiensi layanan keuangan mereka',
    characteristica: 'Pemahaman industri yang mendalam dan pandangan ke depan tren',
    characteristicb: 'Teknologi cerdas terkemuka',
    characteristicc: 'Pengalaman operasi lokalisasi senior',
    characteristicd: 'Tim dan layanan yang profesional dan efisien',
    // banner2
    Slogan2: 'Apa yang bisa kami berikan untuk industri/perusahaan ?',
    characteristic1: 'Inovasi teknologi berkelanjutan menghadirkan manajemen risiko yang lebih baik',
    characteristic2: 'Pengalaman senior pasar lokal untuk memfasilitasi kepatuhan',
    characteristic3: 'Wawasan bisnis profesional untuk meningkatkan pendapatan',
    characteristic4: 'Mengurangi biaya, meningkatkan efisiensi, dan memfasilitasi transformasi digital',
    // ai数字投放
    aiTab: 'Iklan AI',
    aiSlogan: 'Kreativitas Algoritma untuk Pertumbuhan Bisnis',
    productFeatures: 'Fitur Produk',
    aiFeatures1: 'Solusi pertumbuhan yang disesuaikan berdasarkan praktik terbaik',
    aiFeatures2: 'AIGC yang inovatif, produksi bahan massal yang efisien',
    aiFeatures3: 'Algoritme terkemuka mencapai pertumbuhan kuantitatif dan kualitatif',
    aiFeatures4: 'Analisis data multidimensi membawa wawasan yang lebih dalam',
    estimate: 'Menilai dan merencanakan',
    aiLeftListaOne: 'Mendiagnosis dan menemukan masalah saat ini',
    aiLeftListTwo: 'ASO melalui penilaian pembelajaran mesin untuk menemukan materi iklan terbaik untuk klien',
    aiLeftCenter: 'Iklan eksperimental',
    aiCenterList1: 'Buat materi kreatif yang sesuai, seperti kata kunci, gambar, dll;',
    aiCenterList2: 'Sesuaikan strategi penawaran, kumpulkan sampel dalam waktu singkat, dan optimalkan algoritme periklanan melalui pembelajaran mesin.',
    Acquiring: 'Pertumbuhan pelanggan',
    aiRightList1: 'Hasilkan lebih banyak materi iklan melalui teknologi AI;',
    aiRightList2: 'Tingkatkan perolehan pelanggan dan terus optimalkan algoritme dan materi iklan rekomendasi iklan.',
    buttonStr: 'Periksa detailnya',
    // 数字身份识别
    numberTab: 'EKYC',
    numberSlogan: 'Rasakan yang Terbaik dari EKYC yang Aman dan Mulus dengan Teknologi Canggih',
    numberFeatures1: 'Teknologi deteksi OCR dan liveness terkemuka, identifikasi presisi tinggi',
    numberFeatures2: 'Perjalanan pengguna yang mulus dan nyaman',
    numberFeatures3: 'Kecerdasan buatan untuk mencegah penipuan dan melindungi bisnis dari kerugian',
    numberLeftTitle1: 'Kategori gambar: ',
    numberLeftIcon1: 'selfie',
    numberLeftIcon2: 'KTP',
    numberLeftTitle2: 'Jenis teks: ',
    numberLeftIcon3: 'ID perangkat',
    numberLeftIcon4: 'nomor telepon',
    numberLeftIcon5: 'kartu bank',
    numberLeftIcon6: 'nama',
    numberLeftIcon7: 'nomor kartu ID',
    numberCenterStr1: 'Akuisisi',
    numberCenterStr2: 'Verifikasi',
    numberRightIcon1: 'Perbandingan wajah',
    numberRightIcon2: 'pencarian wajah',
    numberRightIcon3: 'perbandingan dokumen',
    numberRightIcon4: 'verifikasi kartu bank',
    numberRightIcon5: 'verifikasi ID perangkat',
    numberRightIcon6: 'verifikasi nomor telepon',
    // 云盾信用分
    creditTab: 'Skor Cloudun',
    creditSlogan: 'Tingkatkan Performa Bisnis Anda dengan Wawasan Kredit yang Andal',
    creditFeatures1: 'Pengalaman praktis yang kaya',
    creditFeatures2: 'Kemampuan algoritmik mutakhir',
    creditFeatures3: 'Model masif, skor beragam',
    creditFeatures4: 'Kinerja risiko yang stabil',
    creditLeftStr1: 'Telepon',
    creditLeftStr2: 'nama',
    creditLeftStr3: 'nomor kartu ID',
    creditLeftStr4: 'perilaku pengguna',
    creditRightStr1: 'permintaan daftar hitam',
    creditRightStr2: 'fitur yang disesuaikan',
    creditRightStr3: 'prapenyaringan',
    creditRightStr4: 'keputusan terarah',
    creditRightStr5: 'keputusan komprehensif',
    // 云盾咨询
    consultTab: 'Kopilot Bisnis',
    consultSlogan: 'Bermitra dengan Kami untuk Pertumbuhan yang Menguntungkan',
    consultFeatures1: 'Pemahaman mendalam tentang kebijakan kepatuhan setempat',
    consultFeatures2: 'Pengalaman yang kaya dalam operasi lokalisasi',
    consultFeatures3: 'Desain produk profesional dan kemampuan pertumbuhan operasional',
    consultFeatures4: 'Model layanan yang disesuaikan "tersusun" yang fleksibel',
    consultFeatures5: 'Manajemen berbasis data, pertumbuhan berbasis data',
    consultStr1: 'Konsultasi bisnis',
    consultStr2: 'implementasi penerapan',
    consultStr3: 'manajemen risiko',
    consultStr4: 'panduan operasi',
    consultStr5: 'analisis bisnis',
    consultStr6: 'layanan bernilai tambah',
    // 行业应用区
    all: 'Total',
    advertisement: 'Periklanan',
    digitalIntelligenceUserExperience: 'Pengalaman pengguna digital',
    riskManagement: 'Manajemen risiko',
    compliantOperationsAndBusinessGrowth: 'Kepatuhan dan pertumbuhan bisnis',
    // 行业应用数据简介
    industryApplications1: 'Algoritma mengoptimalkan ASO untuk meningkatkan kuantitas dan kualitas prospek',
    industryApplications2: 'EKYC, otentikasi identitas jarak jauh dan pengenalan dokumen, menyelesaikan transaksi online',
    industryApplications3: 'Daftar hitam pencarian wajah, basis data besar, identifikasi "orang berisiko"',
    industryApplications4: 'Perbandingan wajah, identifikasi akurat dari "orang yang sama", untuk mencegah risiko penipuan',
    industryApplications5: 'Data massa multidimensi, pencegahan risiko menyeluruh',
    industryApplications6: 'Identifikasi karakteristik multi-hutang, pelacakan dan kontrol siklus hidup pengguna, untuk mencapai prediksi risiko',
    industryApplications7: 'Keluaran fitur yang disesuaikan untuk memenuhi berbagai jenis kebutuhan manajemen risiko',
    industryApplications8: 'Adaptasi multi-skenario skor kredit, maksimalkan manfaat sambil mencegah risiko gagal bayar secara ketat',
    industryApplications9: 'Pemahaman mendalam tentang industri, solusi bisnis lokal',
    industryApplications10: 'Desain produk yang sesuai, mulus, dan berkonversi tinggi',
    industryApplications11: 'Pelacakan operasi bisnis berbasis data, tinjauan analisis bisnis berkala',
    industryApplications12: 'Pengumpulan data global, wawasan tentang status quo, peringatan dini transaksi, pertumbuhan pencapaian',
    // 行业应用标签
    tab1: '#AIGC',
    tab2: '#Pemasaran',
    tab3: '#OCR',
    tab4: '#Kehidupan',
    tab5: '#EKYC',
    tab6: '#Pencarian Wajah',
    tab7: '#Perbandingan Wajah',
    tab8: '#Daftar Hitam',
    tab9: '#Lab Fitur',
    tab10: '#Multi-debtIdentification',
    tab11: '#Fitur Disesuaikan',
    tab12: '#CreditScore',
    tab13: '#Keputusan Risiko',
    tab14: '#Konsultasi Bisnis',
    tab15: '#Solusi Pinjaman Digital',
    tab16: '#Lokalisasi',
    tab17: '#Analisis Bisnis',
    tab18: '#Analisis Operasi',
    tab19: '#Laporan Visual',
    tab20: '#Operasi Berbasis Data',
    // navigtor导航栏
    home: 'Halaman rumah',
    Partners: 'Mitra',
    productMatrix: 'Matriks produk',
    BestPractices: 'Praktik terbaik',
    Aboutus: 'Tentang kami',
    News: 'Berita',
    freeTrial: 'Uji coba gratis',
    // Chinese: 'Cina',
    // English: 'Inggris',
    // Mexico: 'Meksiko',
    // Indonesia: 'Indonesia',
    download: 'Pindai kode untuk mengunduh uji coba',
    // 底部选项
    solve: 'Pola solusi',
    industry: 'Aplikasi industri',
    Rating: 'Skor Kredit Cloudun',
    CompanyProfile: 'Profil Perusahaan',
    development: 'Sejarah Perusahaan',
    cellWe: 'Hubungi kami',
    business: 'Konsultasi Bisnis:',
    customer: 'Layanan pelanggan:',
    // 最佳实践
    headline: 'Mitra teknologi terkemuka yang dipercaya di seluruh dunia',
    subtitle1: 'Solusi',
    subtitle2: 'Profesional,',
    subtitle3: 'Layanan',
    subtitle4: 'Efisien',
    mode1: 'Panggilan awan',
    modeContent1: '“Akses layanan melalui API, fleksibel dan efisien.Produk:EKYC、daftar hitam、fitur、 skor yang dihasilkan.',
    mode2: 'Penyebaran Privatisasi',
    modeContent2: 'Solusi penyesuaian, pelacakan operasi, kontrol keamanan, pertumbuhan laba yang lebih efisien. Produk: Kopilot bisnis.',
    mode3: 'Hosting Proksi',
    modeContent3: 'Produk:Iklan AI;Bertanggung jawab penuh atas pembukaan dan pengelolaan akun, pembuatan materi iklan, penerapan dan optimalisasi strategi.',
    // 关于我们
    CompanyProfile1: 'Didirikan di Shanghai pada 2019, dengan fokus pada “Aplikasi Komersial Ilmu Keputusan”, Cloudun AI mengintegrasikan teknologi, wawasan pelanggan, skenario keuangan, dan kombinasi produk & operasi, yang bertujuan untuk memberdayakan lembaga keuangan tradisional di era big data.',
    CompanyProfile2: 'Tim tersebut memiliki pengalaman kerja yang kaya dari perusahaan keuangan terkenal China pada tahap awal. Kami memiliki wawasan profesional yang mendalam tentang teknologi dan produk keuangan, pemahaman profesional dan analisis kebutuhan pelanggan, landasan teori yang kuat, dan pengalaman praktis yang kaya di bidang manajemen pengendalian risiko.Kami berkomitmen untuk menyediakan kemampuan pengambilan keputusan"" menyeluruh dan menyeluruh bagi perusahaan di era Big Data, baik dengan pengalaman maupun tidak. Dengan mengaktifkan anggota tim analitik dari semua tingkat keahlian untuk menangani semua tugas dalam siklus hidup analitik dengan cara yang sederhana, andal, dan otomatis. Cloudun AI sudah melayani klien di Indonesia, Thailand, Nigeria, dan Meksiko."',
    new2018StrHeader: 'Shanghai Fintek Berdiri, ',
    new2018StrCenter: 'Bergerak dalam pengembangan produk pinjaman barbasis Saas;',
    new2019Str1Header: 'Cloudun Cayman & Singapura ',
    new2019Str1Center: 'didirikan dibulan mei 2019;',
    new2019Str2: 'Perusahaan Grup memulai ekspansi layanan teknologi dan Kecerdasan Buatan (AI) berbasis fintek di Indonesia.',
    new2020StrHeader: 'Cloudun Indonesia ',
    new2020StrCenter: 'dirikan dan memulai menyediakan jasa layanan konsultasi diantaranya : - EKYC, Credit Insight, Digital Lending;',
    new2020Str1Header: 'Dengan berjalannya waktu, pada tahun 2020, Cloudun telah melayani 37 Klien dengan penggunaan API hampir',
    new2020Str1Center: '40 Juta Permintaan.',
    new2021Str1Header: 'Cloudun HK ',
    new2021Str1Center: 'didirikan dan memulai bisnis di meksiko (amerika Latin) dan memperluas jangkuan bisnis serta pemasaran digital; ',
    new2021Str2Header: 'Pada akhir tahun 2021, Cloudun menyediakan layanan untuk 67 klien perusahaan, dengan total panggilan API lebih dari ',
    new2021Str2Center: '90 juta kali.',
    new2022Str1Header: 'Cloudun Grup ',
    new2022Str1Center: 'terus mengoptimalkan produk dipasar Meksiko dan Indonesia, dengan meningkatkan kualitas dan Klien, serta mempertahankan pertumbuhan dengan metode pengembangan yang berkelanjutan. sejauh ini permintaan API sudah mencapai lebih dari ',
    new2022Str1footer: '270 juta permintaan;',
    new2022Str2Header1: 'Pada Desember 2023, cloudun telah menyediakan layanan untuk ',
    new2022Str2Center1: '90 klien ',
    new2022Str2Header2: 'perusahaan dengan nilai transaksi keuangan mencapai ',
    new2022Str2Center2: '3 miliar',
    new2022Str2Header3: 'dolar dan telah menghubungkan hampir ',
    new2022Str2Center3: '50 juta pengguna;',
    new2022Str3: 'Pendanaan Seri A.',
    new2024Str1Center1: 'Berdasarkan usaha mempertahankan pertumbuhan yang stabil serta daya saing dipasar yang ada, ',
    new2024Str1Header: 'cloudun akan ',
    new2024Str1Center2: 'mengembangkan pasar di asia tenggara seperti filipina dan mengembangkan lebih baik dan terarah Kecerdasan buatan untuk perhitungan pintar kredit skor dan produk-produk finansial lainnya;',
    new2024Str2: 'Dan secara aktif mengembangkan investasi disepanjang rantai industri , mencari peluang dan bersinergi.',
    // 产品二级页
    // ai数字广告
    productAITitle: 'Iklan AI',
    productAITitle1: 'AI',
    productAITitle2: 'Iklan',
    productAISubTitle: 'Kreativitas Algoritma untuk Pertumbuhan Bisnis',
    productAIMode1: 'Manfaatkan kemampuan AI untuk membuat dan mendapatkan materi iklan',
    productAIMode2: 'Terus mengoptimalkan kinerja iklan melalui algoritme',
    productAIMode3: 'Memberdayakan klien perusahaan untuk mendapatkan konsumen yang memenuhi syarat dalam skala besar dengan solusi paling hemat biaya.',
    productAIHighlights1: 'Tidak ada petunjuk untuk menawar',
    productAIHighlightScorrect1: 'Pengoptimalan strategi dengan algoritme pembelajaran mesin',
    productAIHighlights2: 'Produksi bahan yang efisien rendah, efek yang buruk',
    productAIHighlightScorrect2: 'Algoritma untuk menemukan kampanye terbaik, AIGC untuk mencapai pengendapan dan penurunan bahan, menghasilkan bahan besar dalam waktu singkat',
    recommendProduct: 'These companies are all using',
    // 数字身份识别
    productNumberTitle1: 'EKYC',
    productNumberTitle2: '',
    productNumberSubTitle: 'Rasakan layanan EKYC yang canggih, aman, dan lancar',
    productNumberMode1: 'Pengumpulan informasi identitas yang efisien, identifikasi yang akurat dari "orang yang sebenarnya", "orang yang sama", "kredit orang yang berisiko"',
    productNumberMode2: 'Nyaman dan akurat',
    productNumberHighlightScorrect1: 'Kirim formulir kertas yang bertanggung jawab atau tanda tangan langsung untuk menyelesaikan transaksi',
    productNumberHighlightScorrect2: 'Membutuhkan proses autentikasi manual yang rumit',
    productNumberHighlightScorrect3: 'Pengalaman login digital kelas satu melalui identifikasi ID dan otentikasi biometrik',
    productNumberHighlightScorrect4: 'Pengumpulan informasi multi-dimensi, identifikasi risiko penipuan yang efisien',
    // 信用评分
    productCreditTitle: 'Skor Kredit Cloudun',
    productCreditTitle1: 'Skor Kredit ',
    productCreditTitle2: 'Cloudun',
    productCreditSubTitle: 'Tingkatkan Performa Bisnis Anda dengan Wawasan Kredit yang Andal',
    productCreditMode1: 'Memberikan wawasan kredit yang sangat akurat menggunakan data masif untuk menganalisis informasi terenkripsi dalam skala besar melalui model penilaian kredit pembelajaran mesin miliknya, Memberdayakan pemberi pinjaman perusahaan untuk membuat keputusan yang lebih tepat tentang kelayakan kredit konsumen individu.',
    productCreditMode2: 'Termasuk Lab Fitur dan Skor yang Dihasilkan.',
    productCreditHighlights1: 'Cara mengidentifikasi penipuan secara lebih komprehensif',
    productCreditHighlightScorrect1: 'Kueri daftar hitam data masif multidimensi',
    productCreditHighlights2: 'Bagaimana meningkatkan strategi pengendalian risiko &gt',
    productCreditHighlightScorrect2: 'Fitur yang disesuaikan, output terarah',
    productCreditHighlights3: 'Pengaruh pengendalian risiko belum diperbaiki',
    productCreditHighlightScorrect3: 'Pengambilan keputusan terintegrasi, wawasan kredit yang andal',
    productCreditHighlights4: 'Menolak beberapa pengguna secara tidak sengaja, mengakibatkan kerugian',
    productCreditHighlightScorrect4: 'Pengambilan keputusan yang ditargetkan, retensi pengguna, dan pemulihan keuntungan',
    // 云盾咨询
    produCtonsultTitle: 'Kopilot Bisnis',
    produCtonsultTitle1: 'Kopilot',
    produCtonsultTitle2: 'Bisnis',
    produCtonsultSubTitle: 'Bermitra dengan Kami untuk Pertumbuhan yang Menguntungkan',
    produCtonsultMode1: 'Dari konsultasi, pembentukan model bisnis, pengembangan dan penerapan yang disesuaikan, hingga panduan operasi dan analisis operasi pada tahap awal bisnis, dan akhirnya ketransfer lengkap strategi operasi dan kode, untuk mencapai solusi bisnis yang komprehensif.',
    produCtonsultHighlights1: 'Tidak ada petunjuk untuk melokalkan pasar baru',
    produCtonsultHighlightScorrect1: 'Pemahaman mendalam tentang industri, solusi bisnis lokal',
    produCtonsultHighlights2: 'Rendahnya konversi pelanggan baru dan rendahnya retensi pelanggan lama',
    produCtonsultHighlightScorrect2: 'Desain produk klien yang patuh, lancar, dan berkonversi tinggi',
    produCtonsultHighlights3: 'Kerugian berkelanjutan atau keuntungan rendah',
    produCtonsultHighlightScorrect3: 'Solusi operasi komersial yang komprehensif',
    // 新增词汇
    statusTitle: 'Status industri',
    advantageTitle: 'Keunggulan solusi',
    modeTitle: 'Pengenalan mode',
    headline1: 'Mitra teknologi terkemuka yang',
    headline2: 'dipercaya di seluruh dunia',
    organizationStructure: 'Struktur Organisasi',
    certificate: 'Lisensi dan Sertifikasi',
    progress: 'Sedang Berjalan',
    registered: 'Terdaftar',
    address: 'Alamat',
    location: 'Lokasi',
    latest: 'Informasi Terbaru',
    next: 'Berikutnya'

  }
}
