<template>
  <div id="app" :class="{ 'app1': isMobileDevice }">
    <srcollTop></srcollTop>
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      isMobileDevice: ''
    }
  },
  created () {
    this.isMobileDevice = window.matchMedia('(max-width: 767px)').matches
  }
}
</script>

<style lang="less">
#app {
  font-family: "Inter";
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1B2E2C;
  margin-top: 1.5rem;
}
.app1 {
  padding-top: 0 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
